.main{
    flex: 1;
    min-height: 100vh;
    padding-bottom: 15px;
    position: relative;
}

.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: #585858;
}
.nav p{
    font-size: 20px;
}
.nav img{
    width: 50px;
    height: 50px;
    border-radius: 50%;

}
.main-container{
    max-width: 1000px;
    margin: auto;
    scroll-behavior: smooth;
}
.greet{
    margin:30px 0px ;
    padding: 20px 15px;
    color: #bbbbbb;
    font-size: 56px;

}
.greet p span{
    background: -webkit-linear-gradient(16deg ,#4b90ff,#ff5546);
    background-clip:text;
    -webkit-text-fill-color: transparent;
}
.cards{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(180px,1fr));
    gap: 15px;
    padding: 20px;
    margin-top: 50px;
    height: auto;
    margin-bottom: 10px;
}
.card{
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    background: #f0f4f9;
    position: relative;
    cursor: pointer;
    margin-top: 25px;
}
.card img{
    width: 35px;
    height: 35px;
    border-radius: 20px;
    position: absolute;
    right: 10px;
    bottom:10px;
    background: white;
    padding: 5px;
}
.card p{
    font-size: 17px;
    color: #585858;
}
.card:hover{
    background-color: #dfe4ea;
}
.main-container-bottom{
    position: absolute;
    bottom: 0px;
    width: 100%;
    max-width: 900px;
    margin: 10px auto;
    /* padding: 0px 20px; */
}
.search-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: #ececec;
    margin: 20px;
}
.search-box img{
    width: 25px;
    margin-right: 10px;
    cursor: pointer;
}
.search-box div{
    display: inline-flex;
}
.search-box input{
    flex: 1;
    background-color: transparent;
    padding: 10px;
    border: none;
    outline: none;
    font-size: 18px;
}
.main-container-bottom p{
     font-size: 14px;
     color: #585858;
     text-align: center;
     padding: 3px;
     margin: 13px 3px;
}
.result{
    padding: 0px 5%;
    max-height: 73vh;
    overflow-y: scroll;
}
/* .result::-webkit-scrollbar{
    display: none;
} */

.result-title{
    display: flex;
    gap: 13px;
    align-items: center;
}

.result-title img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.result-data{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}
.result-data img{
    width: 40px;
    height: 40px;
}
.loader{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.loader hr{
    width: 95%;
    border-radius: 4px;
    border:none;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #9ed7ff, #ffff , #9ed7ff);

    height: 20px;
    
    animation: loader 3s infinite linear;
}
.loader .hr1{
    background-size: 810px;
}
.loader .hr2{
    background-size: 807px;
}
.loader .hr1{
    background-size: 804px;
}
@keyframes loader{
    0%{background-position: -800px 0}
    100%{background-position: 800px 0}
}

.result-data p{
    font-size: 16px;
    line-height: 1.6;
    font-weight: 300;
}

@media (max-width: 600px){
    .main-container-bottom input{
        width: 150px;
        flex: none;
    }
    .main-container-bottom img{
        width: 20px;
    }
    .search-box{
        padding: 5px 10px;
    }
    .search-box div{
        gap: 5px;
    }
    .result::-webkit-scrollbar{
    display: none;
}
.card{
    height: 150px;
}
}

.hid{
    display: none;
}