.sidebar{
    min-height: 100vh;
    background-color: #f5f5f5;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 15px;
    
}
.sidebar img{
    width: 20px;
}
.menu{
    display: block;
    margin-left: 10px;
    cursor: pointer;
    margin-bottom: 40px;
    margin-top: 10px;
}
.new-chat{
    display: inline-flex;
    align-items: center;
    gap: 20px;
    padding: 10px 15px;
    margin-top: 20px;
    background-color: #e6eaf1;
    border-radius: 50px;
    cursor: pointer;
    color: gray;
    font-size: 14px;
}
.recent{
    display: flex;
    flex-direction: column;
    animation: fadin 1.5s;
}
.recent-title{
    font-size: 16px;
    font-weight: 600;
    margin: 20px;
}
.recent-entry{
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 50px;
    background-color: #efeff0;
    padding: 10px 10px;
    cursor: pointer;
}
.recent-entry:hover{
    background-color: #e8e9eb;
}
.bottom{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.bottom-item{
    display: flex;
    justify-content: flex-start;
    gap: 19px;
}

@media (max-width:600px) {
     .sidebar{
display: none;
    }
}