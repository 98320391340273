@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
}
#root{
    animation: fadin 1.5s;
}
@keyframes fadin {
    0%{opacity: 0;}
    100%{opacity: 1;}
}